import './WorkS2.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import Col from '../../../components/Col/Col';
import Heading from '../../../components/Heading/Heading';
import PartnersDisplay from '../../../components/PartnersDisplay/PartnersDisplay';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';

const WorkS2 = ({ headingRT = {}, partners = [] }) => {
  if (!partners.length) {
    return null;
  }

  return (
    <Section className="work-s2">
      <Row mdJustify="center">
        <Col xx={12} md={8} className="work-s2__top">
          {headingRT.text && (
            <ScrollTrigger>
              <Heading>
                <RichText render={headingRT.raw} />
              </Heading>
            </ScrollTrigger>
          )}
        </Col>
      </Row>
      <Row mdJustify="center">
        <Col xx={12} md={10} className="work-s2__bottom">
          <ScrollTrigger>
            <PartnersDisplay partners={partners} />
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default WorkS2;
