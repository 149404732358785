import './WorkExampleBig.scss';

import cx from 'classnames';
import { Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import { useIntl } from 'react-intl';

import { OvalLinkButton } from '../Button/Button';
import Heading from '../Heading/Heading';
import Image from '../Image/Image';
import Text from '../Text/Text';

const WorkExampleBig = ({ btnText, color = 0, headingRT = {}, linkTo, subtitle, workIMG = {} }) => {
  const intl = useIntl();
  const rootCx = cx('work-example-big', `work-example-big--color-${color}`);

  return (
    <div className={rootCx}>
      <Link className="work-example-big__link" to={linkTo} />
      <Text>{subtitle}</Text>
      <Heading>
        <RichText render={headingRT.raw} />
      </Heading>
      {workIMG.fluid && <Image {...workIMG} />}
      <OvalLinkButton to={linkTo}>{btnText || intl.formatMessage({ id: 'common.case_study' })}</OvalLinkButton>
    </div>
  );
};

export default WorkExampleBig;
