import './WorkExampleSmall.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import Heading from '../Heading/Heading';
import LinkImage from '../LinkImage/LinkImage';
import Text from '../Text/Text';

const WorkExampleSmall = ({ headingRT = {}, linkTo, subtitle, workIMG = {} }) => (
  <div className="work-example-small">
    {workIMG.fluid && <LinkImage imgProps={workIMG} to={linkTo} />}
    <Text>{subtitle}</Text>
    <Heading>
      <RichText render={headingRT.raw} />
    </Heading>
  </div>
);

export default WorkExampleSmall;
