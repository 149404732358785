import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import React, { useRef } from 'react';

import WorkHero from './WorkHero/WorkHero';
import WorkS1 from './WorkS1/WorkS1';
import WorkS2 from './WorkS2/WorkS2';

const OurWorkPage = ({
  data: {
    page: {
      data: {
        hero_opening_text,
        section_1_case_studies,
        section_1_layout_type,
        section_2_partners,
        section_2_title,
      } = {},
    } = {},
  } = {},
  location: { pathname },
}) => {
  const heroRef = useRef(null);

  return (
    <React.Fragment>
      {/* Hero */}
      <WorkHero openingRT={hero_opening_text} ref={heroRef} />

      {/* Section 1 */}
      <WorkS1
        animationTrigger={heroRef.current}
        caseStudies={section_1_case_studies}
        layoutType={section_1_layout_type}
        pathname={pathname}
      />

      {/* Section 2 */}
      <WorkS2 headingRT={section_2_title} partners={section_2_partners} />
    </React.Fragment>
  );
};

export default withPreview(OurWorkPage);

export const query = graphql`
  query($lang: String) {
    page: prismicOurWorkPage(lang: { eq: $lang }) {
      data {
        hero_opening_text {
          raw
          text
        }

        section_1_case_studies {
          case_study {
            document {
              ... on PrismicCaseStudyPage {
                id
                uid
                data {
                  domain
                  hero_image {
                    alt
                    fluid(maxWidth: 770) {
                      ...GatsbyPrismicImageFluid_withWebp
                    }
                  }
                  highlight_image {
                    alt
                    fluid(maxWidth: 770) {
                      ...GatsbyPrismicImageFluid_withWebp
                    }
                  }
                  highlight_title {
                    raw
                    text
                  }
                  section_1_title {
                    raw
                    text
                  }
                }
              }
            }
          }
        }
        section_1_layout_type

        section_2_partners {
          partner {
            document {
              ... on PrismicPartner {
                id
                data {
                  logo {
                    alt
                    url
                  }
                }
              }
            }
          }
        }
        section_2_title {
          text
          raw
        }
      }
    }
  }
`;
