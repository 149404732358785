import './PartnersDisplay.scss';

import { isEmpty } from 'lodash';
import React from 'react';

const PartnersDisplay = ({ partners = [] }) => (
  <div className="partners-display">
    {partners.map(
      ({ partner: { document = {} } = {} }) =>
        !isEmpty(document) && (
          <div className="partners-display__icon" key={document.id}>
            <img src={document.data.logo.url} alt={document.data.logo.alt} />
          </div>
        )
    )}
  </div>
);

export default PartnersDisplay;
