import './LinkImage.scss';

import cx from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import Image from '../Image/Image';

const LinkImage = ({ className, imgProps, ...props }) => {
  return (
    <Link className={cx('link-image', className)} {...props}>
      <Image {...imgProps} />
    </Link>
  );
};

export default LinkImage;
