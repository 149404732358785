import './WorkS1.scss';

import { isEmpty } from 'lodash';
import React from 'react';

import Col from '../../../components/Col/Col';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';
import WorkExampleBig from '../../../components/WorkExample/WorkExampleBig';
import WorkExampleSmall from '../../../components/WorkExample/WorkExampleSmall';

const getHeadingRT = ({ highlight_title, section_1_title }) =>
  highlight_title.text ? highlight_title : section_1_title;
const getWorkIMG = ({ highlight_image, hero_image }) =>
  !isEmpty(highlight_image.fluid) ? highlight_image : hero_image;

const WorkS1 = ({ animationTrigger, caseStudies = [], layoutType, pathname }) => {
  if (!caseStudies.length) {
    return null;
  }

  return (
    <Section className="work-s1">
      <Row mdJustify="center">
        {caseStudies.map(
          ({ case_study: { document = {} } = {} }, idx) =>
            !isEmpty(document) &&
            (layoutType === 'card' ? (
              <Col key={document.id} xx={12} md={8} className="work-s1__card">
                <ScrollTrigger
                  allSet={idx === 0 && { to: { delay: 0.4, scrollTrigger: { trigger: animationTrigger } } }}
                >
                  <WorkExampleBig
                    color={idx % 4}
                    subtitle={document.data.domain}
                    headingRT={getHeadingRT(document.data)}
                    linkTo={`${pathname}${document.uid}`}
                    workIMG={getWorkIMG(document.data)}
                  />
                </ScrollTrigger>
              </Col>
            ) : (
              <Col key={document.id} xx={12} md={5} className="work-s1__tile">
                <ScrollTrigger
                  desktopSet={{
                    to: { delay: (idx % 2) / 2 + 0.4, scrollTrigger: idx >= 1 && { trigger: animationTrigger } },
                  }}
                  mobileSet={idx === 0 && { to: { delay: 0.4, scrollTrigger: { trigger: animationTrigger } } }}
                >
                  <WorkExampleSmall
                    domain={document.data.domain}
                    headingRT={getHeadingRT(document.data)}
                    linkTo={`${pathname}${document.uid}`}
                    workIMG={getWorkIMG(document.data)}
                  />
                </ScrollTrigger>
              </Col>
            ))
        )}
      </Row>
    </Section>
  );
};

export default WorkS1;
